<div class="navigation">
    <ul>
        <li> <i class="fas fa-chevron-right arrow"></i> Опис електростанції</li>
        <li> <i class="fas fa-chevron-right arrow"></i> Місцезнаходження</li>
        <li> <i class="fas fa-chevron-right arrow"></i> Основні показники</li>
        <li> <i class="fas fa-chevron-right arrow"></i> Виробництво</li>
        <li> <i class="fas fa-chevron-right arrow"></i> Підрядники</li>
        <li> <i class="fas fa-chevron-right arrow"></i> Обладнання</li>
        <li> <i class="fas fa-chevron-right arrow"></i> Основні дати</li>
        <li> <i class="fas fa-chevron-right arrow"></i> Звіти</li>
    </ul>
</div>