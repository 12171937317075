<div class="home_page">
    <div class="container">
        <div class="navigation">
            <page-navigation></page-navigation>
        </div>

        <div class="content">
            <div class="header ">
                Опис електростанції
            </div>

            <p class="text">
                Сонячна електростанція потужностю 0,45
                МВт передбачаєтсья для виробництва енергії з сонячної енергії. Вироблена електроенергія передається у
                мережу на напрузі 0,4 кВ.
                <br>
                Даний проект попадає під перелік стартегічних пріорітетних напрямків інноваційної діяльності, а саме у
                частині нових та поновлюваних джерел енергії. Будівництво сонячних електростанцій, згідно угоди в рамках
                Рамкової конвенції ООН про зміну клімату (UNFCCC), щодо регулювання заходів зі зменшення викидів
                діоксиду вуглецю з 2020р. Парижського протоколу.
            </p>

            <div class="project_card">
                <i class="far fa-building building"></i>
                <span>
                    ТОВ "Веселка СЕС"
                </span>
            </div>

            <div class="energy_information">
                <div class="item">
                    <i class="far fa-calendar-alt icon"></i>
                    <span class="info_header">В експлуатації з </span>
                    <span class="info_text">Грудень 2019</span>
                </div>
                <div class="item">
                    <i class="fas fa-bolt icon"></i>
                    <span class="info_header">Потужність</span>
                    <span class="info_text">0.518 МВт</span>
                </div>
                <div class="item">
                    <i class="fas fa-euro-sign icon"></i>
                    <span class="info_header">Зелений тариф </span>
                    <span class="info_text">0.15¢</span>
                </div>
            </div>


            <div class="header mt-5">
                Місцезнаходження
            </div>
            <div class="location">
                <!-- <p class="text"><i class="fas fa-map-marked-alt map_icon"></i> м. Тисмениця по вул. К. Левицького
                    Івано-Франківської</p> -->
                <img src="../../../assets/images/map_2.png" alt="Map" class="map_image">
            </div>


            <div class="header mt-5">
                Основні показники
            </div>
            <div class="energy_details">
                <div class="details_item">
                    <img src="../../../assets/images/chart.png" alt="chart" class="chart_img">
                    <div class="numbers">
                        N/A
                    </div>
                    <div class="info">
                        <div class="info_header">КВВП</div>
                        <div class="info_text">
                            Коефіцієнт використання встановленої потужності = середньоарифметична
                            фактична потужность / встановлена потужность електроустаткування (за 12 місяців)
                        </div>
                    </div>
                </div>
                <div class="details_item">
                    <img src="../../../assets/images/chart.png" alt="chart" class="chart_img">
                    <div class="numbers">
                        1210
                    </div>
                    <div class="info">
                        <div class="info_header">Инсоляція</div>
                        <div class="info_text">
                            Середньорічна сума глобального опромінення на квадратний метр, отримана модулями даної
                            системи основі супутникових даних (кВт*год/м2)
                        </div>
                    </div>
                </div>
                <div class="details_item">
                    <img src="../../../assets/images/chart.png" alt="chart" class="chart_img">
                    <div class="numbers">
                        0.155
                    </div>
                    <div class="info">
                        <div class="info_header">
                            Тариф</div>
                        <div class="info_text">
                            Тариф, встановлений НКРЕ
                            (євроцентів/кВт*год)
                        </div>
                    </div>
                </div>
            </div>



            <div class="header mt-5">
                Виробництво
            </div>
            <div class="production">
                <img src="../../../assets/images/production.png" alt="Production">
            </div>

            <div class="header mt-5">
                Підрядники
            </div>
            <div class="contractors">
                <div class="contractor">
                    <i class="fas fa-tools icon"></i>

                    <div class="type">Проектувальник</div>
                    <div class="name">ТОВ "КМК ЕЛЕКТРО"</div>
                </div>
                <div class="contractor">
                    <i class="fas fa-tools icon"></i>

                    <div class="type">Генеральний підрядник</div>
                    <div class="name">ТОВ "КМК ЕЛЕКТРО"</div>
                </div>
            </div>

            <div class="header mt-5">
                Обладнання
            </div>

            <div class="table">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <!-- <th scope="col">Вид</th> -->
                            <th scope="col">Виробник</th>
                            <th scope="col">Модель</th>
                            <th scope="col"><i class="fas fa-bolt"></i></th>
                            <th scope="col"><i class="fas fa-calculator"></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <!-- <th scope="row">1</th> -->
                            <td>Huawei</td>
                            <td>SUN2000-60KTL-M0</td>
                            <td>60.0</td>
                            <td>6</td>
                        </tr>
                        <tr>
                            <!-- <th scope="row">1</th> -->
                            <td>Huawei</td>
                            <td>SUN2000-36KTL</td>
                            <td>36.0</td>
                            <td>2</td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div class="header mt-5">
                Основні дати проекту
            </div>

            <div class="dates">
                <div class="date_item">
                    <div class="date">Трав 2018</div>
                    <div class="date_icon">
                        <i class="far fa-chart-bar dates_icon"></i>
                    </div>
                    <div class="date_info">
                        <div class="info_header">
                            ПОПЕРЕДНІЙ АНАЛІЗ
                        </div>
                        <div class="duration">
                            3 місяці
                        </div>
                    </div>
                </div>
                <div class="date_item">
                    <div class="date">Серп 2018</div>
                    <div class="date_icon">
                        <i class="fas fa-database dates_icon"></i>
                    </div>
                    <div class="date_info">
                        <div class="info_header">
                            Девелопмент
                        </div>
                        <div class="duration">
                            11 місяці
                        </div>
                    </div>
                </div>
                <div class="date_item">
                    <div class="date">17.10.2019</div>
                    <div class="date_icon">
                        <i class="fas fa-shapes dates_icon"></i>
                    </div>
                    <div class="date_info">
                        <div class="info_header">
                            Будівництво
                        </div>
                        <div class="duration">
                            3 місяці
                        </div>
                    </div>
                </div>
                <div class="date_item">
                    <div class="date">23.12.2019</div>
                    <div class="date_icon">
                        <i class="fas fa-spinner dates_icon"></i>
                    </div>
                    <div class="date_info">
                        <div class="info_header">
                            Експлуатація
                        </div>

                    </div>
                </div>
                <div class="date_item">
                    <div class="date">21.02.2020</div>
                    <div class="date_icon"><i class="fas fa-euro-sign dates_icon"></i></div>
                    <div class="date_info">
                        <div class="info_header">
                            Зелений тариф
                        </div>

                    </div>
                </div>
            </div>
            <div class="header mt-5">
                Додаткова інформація
            </div>
            <div class="questions">
                За передплатою ви можете додатково знайти відповіді на наступні питання:
                <ul>

                    <li>Які телефон та email для зв'язку з  ТОВ "Веселка СЕС"?</li>
                    <li>В яку групу входить  ТОВ "Веселка СЕС"?</li>
                    <li>Який поточний тариф в гривнях у  ТОВ "Веселка СЕС"?</li>
                    <li>Чи має бонус за локальну складову  ТОВ "Веселка СЕС" і який його розмір</li>
                    <li>Яка річна виручка  ТОВ "Веселка СЕС"?</li>
                    <li>Яким був обсяг капітальних витрат по  ТОВ "Веселка СЕС"?</li>
                    <li>Який розмір земельної ділянки, на якій розташовується  ТОВ "Веселка СЕС"?</li>
                    <li>Які кадастрові номери ділянок, на яких розташовується  ТОВ "Веселка СЕС"?</li>
                    <li>
                        Які точні координати розташування  ТОВ "Веселка СЕС"?
                    </li>
                </ul>
            </div>

            <div class="header mt-5">
               Звіти
            </div>
            <div class="reports">
                <a class="report" href="#">Звіт</a>
            </div>
        </div>
    </div>
</div>