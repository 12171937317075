<footer>
    <div class="main_footer">
        <div class="about">
            <div class="header">
                ПРО "Веселка СЕС"
            </div>
            <div class="text">
                Проект "Веселка СЕС" присвячений збільшенню прозорості для українського ринку відновлюваної енергії.
            </div>
        </div>

        <div class="about">
            <div class="header">
                Наші контакти
            </div>
            <!-- <div class="text">
                <i class="fas fa-map-marked-alt"></i> м. Тисмениця
            </div> -->
        </div>
    </div>
    <div class="child_footer">
        2019-2021 © Всі права захищені.
    </div>
</footer>